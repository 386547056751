/**
 * @file Questionnaire identité de l'utilisateur
 * @author Craspouille
 */

export function UserFormAddress({ cssClass = "usr-address" }) {
  return (
    <div className={cssClass}>
      <p className="app-font-size-title2">Votre adresse&nbsp;:</p>
      <label htmlFor="address">
        <input
          type="text"
          name="address"
          placeholder="Adresse*"
          title="Adresse*"
          autoFocus
          required
          pattern={"[a-zA-Z]"}
          // pattern={"[0-9]\\s[a-zA-Z]"}
        />
      </label>
      <label htmlFor="zipcode">
        <input
          type="text"
          name="zipcode"
          placeholder="Code postal*"
          title="Code postal*"
          required
          pattern="[0-9]{5}"
          maxLength="5"
          inputMode="numeric"
        />
      </label>
      <label htmlFor="city">
        <input
          type="text"
          name="city"
          placeholder="Ville*"
          title="Ville*"
          required
          pattern={"[a-zA-z]{1,}"}
        />
      </label>
    </div>
  );
}
