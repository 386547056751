/**
 * @file Questionnaire identité de l'utilisateur
 * @author Craspouille
 */

export function UserFormIdentity({ cssClass = "usr-global-identity", state }) {
  return (
    <div className={cssClass}>
      <div className="usr-identity">
        <p className="app-font-size-title2">Votre prénom&nbsp;:</p>
        <label htmlFor="firstName">
          <input
            autoFocus
            type="text"
            name="firstName"
            placeholder={"Prénom*"}
            title={"Prénom*"}
            pattern={"[a-zA-ZÀ-ž]"}
            required
          />
        </label>
        <p className="app-font-size-title2">Votre nom&nbsp;:</p>
        <label htmlFor="lastName">
          <input
            autoFocus
            type="text"
            name="lastName"
            placeholder={"Nom*"}
            title={"Nom*"}
            pattern={"[a-zA-ZÀ-ž]"}
            required
          />
        </label>
      </div>
    </div>
  );
} // End function UserFormIdentity
