import React, { createContext, useState } from "react";

/* Custom components */
import { EmploymentSites } from "../components/home/EmploymentSites";
import { RecruitmentKit } from "../components/home/RecruitmentKit";
import { InformationPanel } from "../components/InformationPanel";
import { SolidaritesNouvellesFaceAuChomageSVG } from "../components/svg/SolidaritesNouvellesFaceAuChomageSVG.jsx";
import { VersatileTitle } from "../components/VersatileTitle";

/* Custom style */
import "../scss/home.scss";
import { Footer } from "../components/Footer";

/*
  We MUST create a context, but its default value is not mandatory.
*/
export const HomeContext = createContext({ dummyDefaultValue: 666 });

export const HomePage = () => {
  /* Manage state of popup box visibility upon its content */
  const [resumeUploadBoxVisibility, setResumeUploadBoxVisibility] =
    useState(false);
  const [
    recommandationLetterUploadBoxVisibility,
    setRecommandationLetterUploadBoxVisibility,
  ] = useState(false);
  return (
    <HomeContext.Provider
      value={{
        resumeUploadBoxVisibility,
        setResumeUploadBoxVisibility,
        recommandationLetterUploadBoxVisibility,
        setRecommandationLetterUploadBoxVisibility,
      }}
    >
      <div className="home">
        <RecruitmentKit />
        <div className="relevant-infos">
          <VersatileTitle titleLevel={1} titleText={"Informations utiles"} />
          <InformationPanel
            cardContentArray={DATABASE["relevant"]}
            title={"Voici du contenu qui pourrait vous intéresser :"}
            id="information-pannel-contenu"
          />
          <InformationPanel
            title={"Où trouver un emploi ?"}
            content={<EmploymentSites />}
            id="information-pannel-ou-emploi"
          />
          <InformationPanel
            cardContentArray={DATABASE["employmentAssistance"]}
            title={"Être accompagné"}
            id="information-pannel-accompnement"
          />
          <InformationPanel
            cardContentArray={DATABASE["applicationCompletion"]}
            title={"Compléter ma candidature"}
            id="information-pannel-postuler"
          />
          <Footer />
        </div>
      </div>
    </HomeContext.Provider>
  );
}; // end function Component Home

/* ============== database-like ============== */

const DATABASE = {
  relevant: [
    {
      subject: "8 conseils pour retrouver un emploi après 50 ans",
      article:
        "Trouver un emploi après 50 ans ? Bien sûr que c’est possible ! À condition de mettre en place la bonne stratégie et d’avoir le bon état d’esprit ! Voici 8 principes clés à garder à l’esprit pour booster votre recherche.",
      link: {
        url: "https://www.pole-emploi.fr/actualites/a-laffiche/2022/8-conseils-pour-retrouver-un-emp.html",
        text: "Découvrir",
      },
      image: {
        src: require("../assets/pics/photos/decrocher-un-job-apres-50-ans-850.jpg"),
        alt: "Illustration Pôle emploi",
      },
    },
    {
      image: {
        src: require("../assets/pics/photos/decouvrez-8-metiers-accessibles-sans-diplome-850x523.jpg"),
        alt: "Illustration Pôle emploi",
      },
      subject: "Découvrez 8 métiers accessibles sans diplôme",
      article:
        "S’ils nécessitent de maîtriser des compétences comportementales ou techniques, ou encore d’avoir suivi des formations, certains métiers ne requièrent ni études ni diplômes. Focus sur 8 d’entre eux qui recrutent.",
      link: {
        url: "https://www.pole-emploi.fr/actualites/a-laffiche/2023/decouvrez-8-metiers-accessibles.html",
        text: "Découvrir",
      },
    },
    {
      image: {
        src: require("../assets/pics/photos/marquage-panier-basket.avif"),
        alt: "Illustration Pôle emploi",
      },
      subject: "Formation certifiante : vos compétences reconnues",
      article:
        "Les formations certifiantes sont d’efficaces leviers pour revenir à l’emploi ou accompagner votre transition professionnelle. Voici comment...",
      link: {
        url: "https://www.pole-emploi.fr/actualites/a-laffiche/2022/formation-certifiante--vos-compe.html",
        text: "Découvrir",
      },
    },
    {
      image: {
        src: require("../assets/pics/photos/4-astuces-pour-trouver-un-job-rapidement.jpg"),
        alt: "Illustration Adecco",
      },
      subject: "Trouver un emploi sans diplôme : c’est possible !",
      article:
        "Vous n’avez pas de diplôme pour appuyer votre recherche d’emploi ? Qu’à cela ne tienne : hôtellerie-restauration, vente, logistique, BTP, transports, propreté… De nombreux secteurs embauchent des personnes sans qualification, et peuvent aussi leur proposer de belles évolutions",
      link: {
        url: "https://www.adecco.fr/actu-job/2022/may/4-astuces-pour-trouver-un-emploi-rapidement",
        text: "Découvrir",
      },
    },
    {
      image: {
        src: require("../assets/pics/photos/personnes-en-reunion-de-bureau.avif"),
        alt: "Illustration Adecco",
      },
      subject: "4 astuces pour trouver un emploi rapidement",
      article:
        "Besoin de trouver du travail sans tarder ? Mettez toutes les chances de votre côté en suivant nos conseils emploi : implication, organisation et petites astuces vous feront sortir du lot.",
      link: {
        url: "https://www.adecco.fr/actu-job/2022/september/trouver-un-emploi-sans-diplome",
        text: "Découvrir",
      },
    },
    {
      image: {
        src: require("../assets/pics/photos/personnes-en-reunion-de-bureau-02.avif"),
        alt: "Illustration Pôle emploi",
      },
      subject: "Explorer les métiers selon mes centres d'intérêt",
      article:
        "Vous n'avez pas encore une idée certaine du métier que vous voulez exercer et souhaitez découvrir les différents métiers ? Pas de soucis ! Dites-nous ce qui vous intéresse et vous trouverez les possibilités d'emplois qui pourraient vous convenir",
      link: {
        url: "https://candidat.pole-emploi.fr/metierscope/centres-interet",
        text: "Découvrir",
      },
    },
  ],
  employmentAssistance: [
    {
      subject: "Projet Voltaire",
      article:
        "Service en ligne de formation à la maîtrise de l’orthographe et de l’expression",
      link: {
        text: "Découvrir",
        url: "https://www.projet-voltaire.fr/offres/particuliers/#email",
      },
      image: {
        src: require("../assets/pics/logos/logo-projet-voltaire.png"),
        alt: "Illustration projet Voltaire",
      },
    },
    {
      subject: "Aktiv'Action",
      article:
        "Ateliers collaboratifs gratuits ouverts à tous pour mieux vivre sa période de transition professionnelle",
      link: {
        text: "Découvrir",
        url: "www.aktivaction.org",
      },
      image: {
        src: require("../assets/pics/logos/logo-aktivaction.jpg"),
        alt: "Illustration Aktiv'Action",
      },
    },
    {
      subject: "Mission locale",
      article:
        "Service d'accompagnement à l'insertion professionnelle pour les jeunes de 16 à 25 ans",
      link: {
        text: "Découvrir",
        url: "https://www.mission-locale.fr/",
      },
      image: {
        src: require("../assets/pics/logos/logo-les-missions-locales.png"),
        alt: "Illustration Mission locale",
      },
    },
    {
      subject: "Plateforme de l'inclusion",
      article:
        "Mettre en relation personnes éloignées de l'emploi avec employeurs solidaires et accompagnants",
      link: {
        text: "Découvrir",
        url: "https://inclusion.beta.gouv.fr/",
      },
      image: {
        src: "https://emplois.inclusion.beta.gouv.fr/static/vendor/theme-inclusion/images/logo-plateforme-inclusion.db50d6602465.svg",
        alt: "Illustration plateforme inclusion",
      },
    },
    {
      subject: "SNC - Solidarités nouvelles face au chômage",
      article:
        "Accompagnement humain et personnalisé pour les chercheurs d'emploi",
      link: {
        text: "Découvrir",
        url: "https://snc.asso.fr/",
      },
      svg: <SolidaritesNouvellesFaceAuChomageSVG />,
    },
    {
      subject: "Cap emploi",
      article:
        "L'EXISTENCE DU LOGO CAP EMPLOI FAIT PRÉSUMER SON INSERTION ICI, MAIS NOUS N'AVONS REÇU NI TITRE NI TEXTE DESCRIPTIF ... ",
      link: {
        text: "Découvrir",
        url: "https://inclusion.beta.gouv.fr/",
      },
      image: {
        src: require("../assets/pics/logos/logo-cap-emploi.png"),
        alt: "Illustration plateforme inclusion",
      },
    },
  ],
  applicationCompletion: [
    {
      subject: "Me préparer à décrocher un nouvel emploi avec Google",
      article:
        "Découvrez comment rédiger un CV, préparer vos entretiens et décrocher votre prochain emploi.",
      link: {
        text: "Découvrir",
        url: "https://learndigital.withgoogle.com/ateliersnumeriques/course/prepare-for-new-job",
      },
      image: {
        src: require("../assets/pics/photos/curriculum-vitae-01.avif"),
        alt: "Illustration décrocher job Google",
      },
    },
    {
      subject: "Simuler un entretien d'embauche",
      article:
        "Avec cet outil, entraînez-vous à répondre aux questions courantes des entretiens d'embauche",
      link: {
        text: "Découvrir",
        url: "https://simulateur.emploi-maintenanceindustrielle.com/",
      },
      image: {
        src: "https://unsplash.com/fr/photos/smartphone-nero-vicino-alla-persona-5QgIuuBxKwM",
        alt: "Illustration",
      },
    },
    {
      subject: "Définir ma personnalité",
      article:
        "En moins de 12 minutes accédez à un descriptif complet de votre personnalité, vos forces, vos axes d'améliorations.",
      link: {
        text: "Découvrir",
        url: "https://www.16personalities.com/fr/test-de-personnalite",
      },
      image: {
        src: "https://unsplash.com/fr/photos/plateau-de-jeu-de-puzzle-en-bois-marron-D44kHt8Ex14",
        alt: "Illustration",
      },
    },
    {
      subject: "Mon tableau de suivi de candidature",
      article: "Organisez vos candidatures grâce à ce tableau.",
      link: {
        text: "Découvrir",
        url: "https://studio3000.notion.site/a0caed0bdec9480296d0538d5611d462?v=02487f0d9db1475882125ef77747c803",
      },
      image: {
        src: "https://unsplash.com/fr/photos/trois-personnes-lors-dune-reunion-a-une-table-discutant-du-calendrier-sur-leur-ordinateur-portable-microsoft-FUGfBZDQOwI",
        alt: "Illustration suivi candidatures",
      },
    },
    {
      subject: "Créer mon CV",
      article:
        "Décrochez l'entretien d'embauche de vos rêves avec CV De Boss ! Postulez à votre emploi grâce à nos modèles de CV gagnants éprouvés",
      link: {
        text: "Découvrir",
        url: "https://cvdeboss.com/",
      },
      image: {
        src: require("../assets/pics/photos/curriculum-vitae-02.avif"),
        alt: "Illustration",
      },
    },
    {
      subject: "Préparer un entretien d'embauche",
      article:
        "L'entretien d'embauche est un temps d’échange qui doit permettre au recruteur de choisir le bon candidat et à ce dernier d'évaluer l'intérêt du poste.",
      link: {
        text: "Découvrir",
        url: "https://www.entreprises.cci-paris-idf.fr/web/rh/entretien-embauche",
      },
      image: {
        src: "https://unsplash.com/fr/photos/due-donne-sedute-accanto-al-tavolo-e-parlando-LQ1t-8Ms5PY",
        alt: "Illustration préparation entretien",
      },
    },
  ],
};
