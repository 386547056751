/**
 * @file Questionnaire sudo systemctl stop apache2sudo systemctl stop apache2e de l'entreprise
 * @author Craspouille
 */

export function CompanyFormAddress() {
  return (
    <div className="empl-address">
      <p className="app-font-size-title2">Adresse de l'employeur&nbsp;:</p>
      <label htmlFor="address">
        <input
          autoFocus
          type="text"
          name="address"
          placeholder="Adresse*"
          // pattern={"[0-9]\\s[a-zA-Z]"}
          required
        />
      </label>
      <label htmlFor="zipcode">
        <input
          type="text"
          name="zipcode"
          placeholder="Code postal*"
          title="Code postal*"
          required
          pattern="[0-9]{5}"
          maxLength="5"
          inputMode="numeric"
        />
      </label>
      <label htmlFor="city">
        <input
          type="text"
          name="city"
          placeholder="Ville*"
          title="Ville*"
          required
          pattern={"[a-zA-z]{1,}"}
        />
      </label>
    </div>
  );
}
