import React, { useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import Typewriter from "typewriter-effect";
import { AppContext } from "../App";

/* Custom style */
import "../scss/introduction-page.scss";
import { Footer } from "../components/Footer";
import { FriendlyCaptcha } from "../components/FriendlyCaptcha";

export const IntroductionPage = () => {
  const { captchaVerified, setCaptchaVerified } = useContext(AppContext);
  const navigate = useNavigate();

  const clickHandler = () => {
    if (captchaVerified) {
      navigate("/Questionnaire");
      return;
    } else {
      alert("Nous devons vérifier que vous n'êtes pas un robot.");
      return;
    }
  };

  return (
    <div className="fullpage">
      <div className="widecontent">
        <div id="typewriter-zone">
          <Typewriter
            onInit={(typewriter) => {
              typewriter
                .changeDelay(65)
                .typeString(
                  "En moins de 10 questions simples<br/>nous allons générer ensemble<br/>votre lettre de motivation..."
                )
                .start();
            }}
          />
        </div>
      </div>
      <div className="control-widget-captcha">
        {!captchaVerified && <FriendlyCaptcha />}
      </div>
      <div className="controls">
        <span>Pour commencer</span>
        <div className="control-button">
          <button onClick={clickHandler}>
            <span>Cliquer ici</span>
          </button>
        </div>
      </div>
      <Footer />
    </div>
  );
};
