/**
 * @file Créé un champ pour une réponse personnalisée
 * @author Prof. Chaos
 */

import React, { useEffect, useState } from "react";
// import { useNumberInput } from '@chakra-ui/react';

/* Custom components */
import { CrossSVG } from "../svg/CrossSVG";

export function QuestionnaireFormFreeTextInput({
  canClose,
  choice,
  isUserChecked,
  isUserInput = false,
  selectChoice,
  ...extras
}) {
  const [userInput, setUserInput] = useState(choice.userAnswer);
  const [isValid, setValid] = useState(choice.isValid);

  function autoresize(e) {
    let t = e.target;
    t.style.height = "auto";
    t.style.height = t.scrollHeight + "px";
    t.scrollTop = t.scrollHeight;
    window.scrollTo(window.scrollLeft, t.scrollTop + t.scrollHeight);
  }

  function closeFormFreeTextInput(e) {
    selectChoice(choice, false);
  }

  function validate(e) {
    let t = e.target;
    let isValid = t.checkValidity();
    choice.isValidAnswer = isValid;
    setUserInput((isValid && e.target.value) || null);
  }

  useEffect(
    (_) => {
      choice.isValid = isValid;
      selectChoice(choice, isValid, true);
    },
    [isValid]
  );

  useEffect(
    (_) => {
      if (userInput) {
        choice.userAnswer = userInput;
      } else {
        choice.userAnswer = null;
      }
      setValid(!!userInput);
    },
    [userInput]
  );

  return (
    <div className="textarea-container">
      {canClose && (
        <div
          className="closing-widget"
          onClick={(e) => closeFormFreeTextInput(e)}
        >
          <CrossSVG />
        </div>
      )}
      <textarea
        // autoFocus={true}
        data-index={choice.index}
        defaultValue={userInput}
        maxLength={500}
        minLength={3}
        name="Réponse personnalisée"
        onChange={(e) => validate(e)}
        placeholder="Votre réponse SVP..."
        // onInput={autoresize}
        {...extras}
      />
    </div>
  );
}

// FormFreeTextInput.displayName = "Réponse libre";
