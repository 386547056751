/**
 * @file Modification de paragraphe par l'utilisateur
 * @author Craspouille
 */
import { useContext, useEffect, useRef } from "react";

/* Button */
import { VersatileButton } from "../../VersatileButton";

/* Context */
import { AppContext, localStorageKeys } from "../../../App";
import { ModifyContext } from "../../../pages/LetterPage";
/**
 *
 * @param {Object} props
 * @param {onSubmit} Validation du formulaire
 * @param {auto_grow} Fonction d'adptaion de la hauteur du textarea selon son contenu
 * @param {ModifyContext} Context
 * @param {modifyParagaraphRef} Référence de l'input modificateur de paragraphe
 * @param {selection} Object (UseRef) des données de sélection
 * @returns Superman
 */
export function LetterModifyParagraphForm() {
  /* Context */
  let {
    auto_grow,
    modifyParagaraphRef, // Ref input de la modification d'un paragraphe de la lettre de motivation
    selection,
  } = useContext(ModifyContext);

  let { modifiedLetter, setModifiedLetter } = useContext(AppContext);

  /* UseRef */
  let oldSentence = useRef({ old: null }).current;

  /* UseEffect */
  useEffect(
    (_) => {
      if (!modifyParagaraphRef.current) return;
      // Remplissage du textarea
      // Fill out the textarea

      modifyParagaraphRef.current.value = selection.select;
      // Remplissage du paragraphe

      // Fill out the paragraph
      // modifyCitationRef.current.innerHTML = `&laquo;&nbsp;${selection.select}&nbsp;&raquo`;
      // Adaptation automatique du textarea receveur
      // Automatic adaptation of the textarea receiver
      modifyParagaraphRef.current.onInput = auto_grow(
        modifyParagaraphRef.current
      );
      modifyParagaraphRef.current.oncontextmenu = (e) => {
        e.preventDefault();
      };
    },
    [modifyParagaraphRef]
  );

  //////////////////////////////////////////////////////////////////////

  // Sélection du texte ciblé afin de changer son contenu
  // Selected targeted text to change its content
  function changeSelection(e) {
    // État précédent du paragraphe lors de la sélection
    // Previous status of the paragraph in selection
    oldSentence.old = selection.ref.value;
    // Sauvegarde de la phrase sélectionnée
    // Save the selected sentence
    oldSentence.sentence = selection.select;
    // Récupération du paragraphe de la sélection
    // Retrieval of the selection paragraph
    // console.log("====", selection, selection.ref, selection.ref.value);
    const tmpModifiedLetter = selection.ref.value.replace(
      selection.select,
      modifyParagaraphRef.current.value
    );
    setModifiedLetter(tmpModifiedLetter);
    localStorage.setItem(localStorageKeys.modifiedLetter, tmpModifiedLetter);

    // console.log(
    //   "%c MODIFIED Letter avant setModified:",
    //   "background: gold; color: grey ; font-weight: bolder",
    //   modifiedLetter
    // );
    // console.log("====", modifyParagaraphRef.current);
    selection.select = "";
  } // End function changeSelection

  function returnSelection() {
    // Retourne l'état précédent du paragraphe lors de la sélection dans le paragraphe et l'objet
    // Returns the previous state of the paragraph when selecting in the paragraph and object
    selection.ref.value = oldSentence.old;
    // TODO ici il faut remttre modifiedLetterLocalStorage back
    // letterContent.paragraph[selection.id].paragraphe = oldSentence.old;
    modifyParagaraphRef.current.value = oldSentence.sentence;
  } // End function returnSelection

  // let { disabled, setDisabled } = true

  let validateParam = {
    ...(modifyParagaraphRef.current &&
      modifyParagaraphRef.current.value.length <= 1 && { disabled: true }),
  }; // End validateParam

  let cancelParam = {
    ...(oldSentence.old === selection.ref.value && { disabled: true }),
  }; // End cancelParam

  return (
    <>
      <p className="app-font-size-title2">Modifier un paragraphe&nbsp;:</p>
      {/* <cite className="selection" ref={modifyCitationRef}></cite> */}
      <textarea
        className="modify-area"
        ref={modifyParagaraphRef}
        type="text"
        placeholder="Écrire ici"
      ></textarea>
      <div className="form-widget-steps">
        <VersatileButton
          {...cancelParam}
          content="Annuler la modification"
          cssClass="previous-button"
          onClickCallback={returnSelection}
        />
        <VersatileButton
          {...validateParam}
          content="Valider la modification"
          cssClass="previous-button"
          onClickCallback={changeSelection}
        />
      </div>
    </>
  );
}
