/**
 * @file Generates an answers panel for each question
 * @author Prof. Chaos
 */

import React, { Fragment, useEffect, useState } from "react";

/* Custom data / JSON */
// questions and possible answers skeleton data
import RAW_QUESTIONNAIRE from "../../database/questionnaire.json";

/* Custom components */
import { Breadcrumbs } from "./Breadcrumbs";
import { Question } from "./Question";
import { QuestionnaireForm } from "./QuestionnaireForm";

export const Questionnaire = () => {
  // Predefined steps data used to generate label crumbs/tabs, each tab being linked to a step
  const [steps, setSteps] = useState([]);
  // Predefined questions data
  const [questions, setQuestions] = useState([]);
  const [nextQuestion, setNextQuestion] = useState(null);
  const [nextQuestionId, setNextQuestionId] = useState(-2);
  const [path, setPath] = useState([]);

  useEffect((_) => {
    if (!RAW_QUESTIONNAIRE) return; // questions and possible answers skeleton data is mandatory
    console.assert(
      RAW_QUESTIONNAIRE.steps,
      `@@@ Questionnaire corrompu : Tableau d'étapes manquant (steps[])`
    );
    if (RAW_QUESTIONNAIRE.steps) {
      let tmpSteps = [];
      let tmpQuestions = {};
      console.assert(
        RAW_QUESTIONNAIRE.steps && RAW_QUESTIONNAIRE.steps instanceof Array,
        `@@@ Questionnaire corrompu :Erreur de type : étapes doit être un tableau (steps[])`
      );
      if (
        !(RAW_QUESTIONNAIRE.steps && RAW_QUESTIONNAIRE.steps instanceof Array)
      )
        return;
      RAW_QUESTIONNAIRE.steps.forEach(function (rawStep, sIndex) {
        console.assert(
          !tmpSteps.some((s) => s.id === rawStep.id),
          `@@@ Étape corrompue [${sIndex}] : Id déjà utilisé [${rawStep.id}]`
        );
        console.assert(
          rawStep.label,
          `@@@ Étape corrompue [${rawStep.id}] : Étiquette manquante (label)`
        );
        console.assert(
          rawStep.questions,
          `@@@ Étape corrompue [${rawStep.id}] : Tableau de questions manquant (questions[])`
        );
        console.assert(
          rawStep.questions && rawStep.questions instanceof Array,
          `@@@ Étape corrompue [${rawStep.id}] : Erreur de type : questions doit être un tableau (questions[])`
        );
        let stepId = "step:" + (rawStep.id || sIndex);
        /* Prepare current step data to be added to stepsList (used to create crumb tabs) */
        let step = {
          id: stepId,
          index: sIndex,
          label: rawStep.label,
          isMulti: rawStep.isMulti || null,
        };
        tmpSteps.push(step);
        if (!(rawStep.questions && rawStep.questions instanceof Array)) {
          return;
        }
        rawStep.questions.forEach &&
          rawStep.questions.forEach(function (rawQst, qIndex) {
            let qGlobalIndex = Object.keys(tmpQuestions).length;
            console.assert(
              rawQst.id,
              `@@@ Question corrompue [step : ${sIndex}, index : ${qIndex}] : Id unique manquant (id)`
            );
            console.assert(
              !Object.keys(tmpQuestions).some((q) => q.id === rawQst.id),
              `@@@ Question corrompue [${rawQst.id}] : Id déjà utilisé [step : ${sIndex}, index : ${qIndex}]`
            );
            console.assert(
              rawQst.title,
              `@@@ Question corrompue [${rawQst.id}] : Titre manquant (title)`
            );
            console.assert(
              rawQst.to,
              `@@@ Question corrompue [${rawQst.id}] : Destination manquante (to)`
            );
            console.assert(
              rawQst.choices,
              `@@@ Question corrompue [${rawQst.id}] : Tableau de choix manquant (choices[])`
            );
            console.assert(
              rawQst.choices && rawQst.choices instanceof Array,
              `@@@ Question corrompue [${rawQst.id}] : Erreur de type : choices doit être un tableau (choices[])`
            );
            console.assert(
              !rawQst.isMulti ||
                new Set(
                  Array.from(choices).map((c) => {
                    return c.to;
                  })
                ).size === 1,
              `@@@ Question corrompue [${rawQst.id}] : Mode multichoix incompatible avec destinations multiples`
            );
            console.assert(
              !rawQst.isDirectInput ||
                (rawQst.isDirectInput && rawQst.choices.length === 1),
              `@@@ Question corrompue [${rawQst.id}] : Il ne peut pas y avoir plusieurs choix avec directInput.`
            );

            let choices = [];
            if (!(rawQst.choices && rawQst.choices instanceof Array)) {
              return;
            }
            rawQst.choices.forEach(function (rawChoice, cIndex) {
              // console.assert(
              //   rawChoice.label || rawQst.isDirectInput,
              //   `@@@ Choix corrompu [question : ${rawQst.id}, choix : ${cIndex}] : Texte de choix manquant (label)`
              // );
              let choiceId = "q:" + rawQst.id + "-c:" + cIndex;
              let choice = {
                ...rawChoice,
                id: choiceId,
                index: cIndex,
                qIndex: qGlobalIndex,
                sIndex: sIndex,
                isValid: !rawChoice.isUserInput || null,
              };
              //   rawChoice.to = rawChoice.to ?? rawQst.to;
              choice.to = rawChoice.to ?? rawQst.to;
              choices.push(choice);
            });
            let question = {
              selectedChoices: new Set(),
              index: qGlobalIndex,
              sIndex: sIndex,
              id: rawQst.id,
              title: rawQst.title,
              to: rawQst.to,
              choices: choices,
              helpParagraphs: rawQst.helpParagraphs || null,
              isValidByDefault: rawQst.isValidByDefault || null,
              isDirectInput: rawQst.isDirectInput || null,
            };

            question["component"] = (
              <Question
                key={question.id}
                setNextQuestionId={setNextQuestionId}
                selectedChoices={question.selectedChoices}
                myQuestion={question}
              />
            );
            tmpQuestions[rawQst.id] = question;
          });
      });
      setSteps(tmpSteps);
      setQuestions(tmpQuestions);

      setPath([tmpQuestions[RAW_QUESTIONNAIRE.steps[0].questions[0].id]]);
    }
  }, []);
  // Lorsque l'on obtient l'id de la future question
  // On indique qu'il y a une prochaine question disponible
  useEffect(
    (_) => {
      // Fin du formulaire
      if (nextQuestionId === -1) {
        setNextQuestion(-1);
      }
      // Fin non atteinte
      else {
        setNextQuestion((nextQuestionId && questions[nextQuestionId]) || null);
      }
      // console.log("nextquestionid", nextQuestionId, questions);
    },
    [nextQuestionId]
  );

  const goToStepQuestion = function (stepNumber) {
    let quest = path.find((question) => question.sIndex === stepNumber);
    console.assert(
      quest,
      "@@@ <Questionnaire.jsx> - Find (Défaillance causée par une erreur du programmeur)"
    );
    if (quest) {
      while (path.slice(-1)[0] !== quest) path.pop();
      setPath([...path]);
    }
  }; // end function goToStepQuestion

  return (
    <Fragment>
      <Breadcrumbs
        steps={steps}
        currentStepIndex={
          (path && path.length && path.slice(-1)[0].sIndex) ?? -1
        }
        goToStepQuestion={goToStepQuestion}
      />
      <div className="experience-questionnaire">
        <QuestionnaireForm
          questions={questions}
          nextQuestion={nextQuestion}
          path={path}
          setPath={setPath}
          nextQuestionId={nextQuestionId}
        />
      </div>
    </Fragment>
  );
};

// Questionnaire.displayName = "Formulaire de questions";
