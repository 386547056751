/**
 * @file Remplissage des paragraphes la lettre de motivation
 * @author Craspouille
 */
import { useContext, useEffect, useRef, useState } from "react";
/* Context */
import { AppContext } from "../../../App";
import { ModifyContext } from "../../../pages/LetterPage";

function formatCarriageReturns(textWithAntiSlashN) {
  // Be careful not to remove the breakline between backsticks !
  if (textWithAntiSlashN) {
    return textWithAntiSlashN
      .replaceAll(
        /\\n/g,
        `
      `
      )
      .replaceAll('"', "");
  } else {
    return <h2>Ouuuuuuuuuuups</h2>;
  }
}

export function ParagraphLetterDetails(props) {
  let paragraphe = useRef({}).current;
  /* UseRef */
  let [coordonneesSelection, setCoordonneesSelection] = useState({
    start: null,
    end: null,
  });

  /* Context */
  let {
    auto_grow,
    letterBodyRef,
    letterParagaraphRef,
    modifyParagaraphRef,
    /* Modification des paragraphe */
    /* Change of paragraph */
    setSelection,
    selection,
    /* End modification des paragraphe */
    /* End change of paragraph */
    textArea,
  } = useContext(ModifyContext);

  let { originalLetter, modifiedLetter } = useContext(AppContext);
  let { emplDetails, setStep } = useContext(ModifyContext);

  let [tooltipVisible, setTooltipVisible] = useState(true);

  /**
   * @param {event} Object
   */
  function handleSelection(event) {
    setTooltipVisible(false);
    if (!event.nativeEvent.target.value) return;
    // Récupération de la coordonées de départ de type number
    // Retrieval of the initial coordinates of type number
    coordonneesSelection.start = event.nativeEvent.target.selectionStart;
    // Récupération de la coordonées d'arrivée de type number
    // Retrieval of type number arrival coordinates
    coordonneesSelection.end = event.nativeEvent.target.selectionEnd;
    setCoordonneesSelection({ ...coordonneesSelection });
    // Récupération de la valeur de la sélection
    // Retrieval the value of the selection
    selection.select = event.nativeEvent.target.value.substring(
      coordonneesSelection.start,
      coordonneesSelection.end
    );
    // Identification du paragraphe ciblé
    // Targeted paragraph identification
    selection.ref = event.target;
    // Et de l'ID
    // And ID
    selection.id = event.target.id;
    setSelection({ ...selection });
    // Si une sélection à été faite
    // If a selection has been made
    if (
      coordonneesSelection.start >= 0
      // &&
      // coordonneesSelection.end > coordonneesSelection.start
    ) {
      if (modifyParagaraphRef.current) {
        // Remplissage du textarea
        // Fill out the textarea
        modifyParagaraphRef.current.value = selection.select;
        // Adaptation automatique du textarea receveur
        // Automatic adaptation of the textarea receiver
        event.nativeEvent.target.onInput = auto_grow(
          modifyParagaraphRef.current
        );
      }
      setStep(2);
    } // else setStep(0);
  }

  useEffect(
    (_) => {
      if (!letterParagaraphRef.current) return;
      letterParagaraphRef.current.childNodes.forEach((textarea) => {
        // Adaptation automatique du textarea receveur
        // Automatic adaptation of the textarea receiver
        textarea.onInput = auto_grow(textarea);
        // Suppression de contextmenu qui gêne la sélection en tactile
        // Deletion of contextmenu that hinders tactile selection
        letterBodyRef.current.oncontextmenu = (e) => {
          e.preventDefault();
        };
      });
    },
    [letterParagaraphRef]
  );

  // useEffect(_ => {
  //   if (!newParagaraphRef.current) return;
  //   newParagaraphRef.current.onInput = auto_grow(newParagaraphRef.current);
  // }, [newParagaraphRef]);
  // console.log("modifiedLetter", modifiedLetter);
  // console.log("après format", formatCarriageReturns(modifiedLetter));

  const textareaClass = tooltipVisible ? "active-tooltip" : "";

  return (
    <div className="letter-content">
      <div className="container-paragraph" ref={letterParagaraphRef}>
        {tooltipVisible && (
          <span id="tooltip">
            <span id="tooltip-content">
              Sélectionnez un passage pour le modifier
            </span>
            <span id="tooltip-arrow"></span>
          </span>
        )}
        <textarea
          data-steppers={4}
          className={textareaClass}
          style={{ textIndent: "20px" }}
          value={
            modifiedLetter
              ? formatCarriageReturns(modifiedLetter)
              : formatCarriageReturns(originalLetter)
          }
          id="letter_modifying"
          readOnly
          ref={textArea}
          onSelect={handleSelection}
          type="text"
        ></textarea>
      </div>
    </div>
  );
}
