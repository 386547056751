/**
 * @file Composant Choix
 * @author Prof. Chaos
 */

import React, { useContext, useRef } from "react";
import { AppContext, localStorageKeys } from "../../App";

export const QuestionnaireFormMutexInput = ({
  choice,
  selectChoice,
  isUserChecked,
}) => {
  const {
    setValueNineRef,
    setValueTenRef,
    loadingQuestionNine,
    loadingQuestionTen,
  } = useContext(AppContext);

  const nineRef = useRef(null);
  const tenRef = useRef(null);

  const handleClickNine = (event) => {
    const spanValue = event.target.innerText;
    setValueNineRef(spanValue);
    // You can then do whatever you want with the span value, like updating state or performing other actions
  };

  const handleClickTen = (event) => {
    const spanValue = event.target.innerText;
    setValueTenRef(spanValue);
    // You can then do whatever you want with the span value, like updating state or performing other actions
  };

  let choiceNine = null;
  let choiceTen = null;
  const localStorageNine = localStorage.getItem(
    localStorageKeys.answerNineChoices
  );
  const localStorageTen = localStorage.getItem(
    localStorageKeys.answerTenChoices
  );

  //   Remove first and last parenthesis if present
  const clearParenthesis = (choice) => {
    if (choice[0] === "(") {
      choice = choice.slice(1);
    }
    if (choice[choice.length - 1] === ")") {
      choice = choice.slice(0, -1);
    }
    return choice;
  };
  const choicesNine = localStorageNine
    ?.split(/\\n-|"/)
    .filter(Boolean)
    .map(clearParenthesis);
  const choicesTen = localStorageTen
    ?.split(/\\n-|"/)
    .filter(Boolean)
    .map(clearParenthesis);

  if (choicesNine && choice.to === "cetr") {
    if (choice.index === 0) {
      choiceNine = choice.label.replace(choice.label, choicesNine[0]);
    } else if (choice.index === 1) {
      choiceNine = choice.label.replace(choice.label, choicesNine[1]);
    } else if (choice.index === 2) {
      choiceNine = choice.label.replace(choice.label, choicesNine[2]);
    } else if (choice.index === 3) {
      choiceNine = "Autre";
    }
  }
  if (choicesTen && choice.to === -1) {
    if (choice.index === 0) {
      choiceTen = choice.label.replace(choice.label, choicesTen[0]);
    } else if (choice.index === 1) {
      choiceTen = choice.label.replace(choice.label, choicesTen[1]);
    } else if (choice.index === 2) {
      choiceTen = choice.label.replace(choice.label, choicesTen[2]);
    } else if (choice.index === 3) {
      choiceTen = "Autre";
    }
  }
  let onChangeHandler = (e) => selectChoice(choice, !isUserChecked);
  if (
    (choice.to === "cetr" && loadingQuestionNine) ||
    (choice.to === -1 && loadingQuestionTen)
  ) {
    // Disable until choices are loaded
    onChangeHandler = () => {};
  }
  //   If last question, add a specific class to make
  //   the choices smaller as they are longer
  const labelClassName = choice.to === -1 ? "question-ten" : "";
  return (
    <>
      <div className="choice">
        <input
          readOnly={true}
          onChange={onChangeHandler}
          className="listed-answer"
          type="checkbox"
          id={choice.id}
          name={`question-${choice.qIndex}`}
          value={choice.index ?? ""}
          userchecked={(isUserChecked && "true") || null}
          data-index={choice.index}
        />
        <label htmlFor={choice.id} className={labelClassName}>
          {choice.to !== "cetr" && choice.to !== -1 && (
            <span key={choice.id}>{choice.label}</span>
          )}

          {choice.to === "cetr" &&
            (loadingQuestionNine ? (
              <span className="loader"></span>
            ) : (
              <span key={choice.id} ref={nineRef} onClick={handleClickNine}>
                {choiceNine}
              </span>
            ))}
          {choice.to === -1 &&
            (loadingQuestionTen ? (
              <span className="loader"></span>
            ) : (
              <span key={choice.id} ref={tenRef} onClick={handleClickTen}>
                {choiceTen}
              </span>
            ))}
        </label>
      </div>
    </>
  );
};

// Choice.displayName = "Proposition unitaire";
